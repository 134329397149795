.plugin-list__infos {
  header {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      border: 1px solid var(--color--neutral--030);
      padding: var(--spacing--05) var(--spacing--15);
      background-color: var(--color--neutral--030);
      font-size: var(--font-size--sm-xx);
      border-radius: 0.2rem;
      font-weight: 600;
    }
  }
}
